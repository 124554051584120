import { registerApplication, start } from "single-spa";

import { fetchAccessToken } from "@bjep-extmod/ispf-auth";
window.addEventListener("load", () => {

  // Apps erst registrieren wenn ein gültiger access token vorliegt
  // const permission = new RBACRequest();
  // use RBACRequest for rbac permission check on demand
  fetchAccessToken().then((token) => {
    if (token && token != null) {
      registerApplication({
        name: "platform",
        app: () => System.import("@bjep/platform-backend"),
        activeWhen: () => true,
        customProps: {
          accessToken: token.accessToken,
        },
      });

      registerApplication({
        name: "backend-accountmanagement",
        app: () => System.import("@bjep-backend/accountmanagement"),
        activeWhen: ["/budget"],
        customProps: {
          accessToken: token.accessToken,
        },
      });
    }
  })
});

window.addEventListener("single-spa:before-routing-event", () => {
  fetchAccessToken();
});

start({
  urlRerouteOnly: true,
});

import { registerApplication, start, getAppStatus, MOUNTED } from "single-spa";

import { fetchAccessToken } from "@bjep-extmod/ispf-auth";
window.addEventListener("load", () => {

  // Apps erst registrieren wenn ein gültiger access token vorliegt
  // const permission = new RBACRequest();
  // use RBACRequest for rbac permission check on demand
  fetchAccessToken().then((token) => {
    if (token && token != null) {
      registerApplication({
        name: "platform",
        app: () => System.import("@bjep/platform-backend"),
        activeWhen: () => true,
        customProps: {
          accessToken: token.accessToken,
        },
      });

      // lets wait for platform to be mounted 
      const observer = new MutationObserver(() => {
        if (getAppStatus("platform") === MOUNTED) {
          observer.disconnect()

          registerApplication({
            name: "backend-accountmanagement",
            app: () => System.import("@bjep-backend/accountmanagement"),
            activeWhen: ["/budget"],
            customProps: {
              accessToken: token.accessToken,
            },
          });

          registerApplication({
            name: "bjep-logoservice-admin",
            app: () => System.import("@Bjep/logoService"),
            activeWhen: ["/logoservice"],
            customProps: {
              accessToken: token.accessToken,
            },
          });
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true
      })
    }
  })
});

window.addEventListener("single-spa:before-routing-event", () => {
  fetchAccessToken();
});

start({
  urlRerouteOnly: true,
});
